import React, { useState, useEffect } from 'react'
import proc from '../../assets/header/proc.png'
import Profile from '../../assets/header/img4.png'
import Notification from '../../assets/header/msg.svg'
import Dasboard from '../../assets/header/dasboard.svg'
import MyExam from '../../assets/header/myexam.svg'
import StudyMaterial from '../../assets/header/Study Material.svg'
import Help from "../../assets/header/help.svg"
import Linkdin from "../../assets/header/linkdin.svg"
import LiveClass from '../../assets/header/Live_Classes.svg'
import Assignment from '../../assets/header/Assingment.svg'
import TimeTable from '../../assets/header/Time Table.svg'
import Document from '../../assets/header/documents.svg'
import Products from '../../assets/header/products.svg'
import Facebook from '../../assets/header/Facebook.svg'
import Whatsapp from '../../assets/header/whatsapp.svg'
import Youtube from '../../assets/header/youtube.svg'
import instagram from '../../assets/header/instagram.svg'
import ExamReport from '../../assets/header/examreportimg.svg'
import Announcement from './announcement/announcement'
import AnnouncementImg from "../../assets/header/img5.png"
import Profile_page from './profile'
import { connect } from 'react-redux'
import imgprofile from '../../assets/profile/profile_image2.png'
import api from '../../api/api'
import ReactModal from 'react-modal'
import config from '../../config'

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAnnouncement, setIsAnnouncement] = useState(false)
  // const [announcements, setannouncements] = useState(true)
  // const [announcementmsg, setannouncementmsg] = useState([])
  let path = ''
  if (window.location.host == 'localhost:3000') {
    path = `http://localhost:3000`;
  } else {
    path = `https://` + window.location.host;
    //path = `https://`+window.location.host+'/my-account'
  }
  const list = props.auth.user_type == 99 ? [
    {
      link: '/dashboard',
      text: 'Home',
      icon: Dasboard,
      show: true,

    },
    {
      link: '/timetable',
      text: 'Time Table',
      icon: TimeTable,
      show: true,
    },
    {
      link: '/studymaterial',
      text: 'Study Materials',
      icon: StudyMaterial,
      show: true,
    },
    
    {
      link: '/live',
      text: 'Live Classes',
      icon: LiveClass,
      show: true,
    },
    {
      link: '/exam',
      text: 'My Exam',
      icon: MyExam,
      show: true
    },
    {
      link: '/report',
      text: 'Exam Reports',
      icon: ExamReport,
      show: true
    },
    {
      link: '/document',
      text: 'Documents',
      icon: Document,
      show: props.auth.inst_set_up[18] 
    },

    {
      link: '/product',
      text: 'My Products',
      icon: Products,
      show: true
    },
  ] : [
    {
      link: '/dashboard',
      text: 'Home',
      icon: Dasboard,
      show: true,
    },
    {
      link: '/timetable',
      text: 'Time Table',
      icon: TimeTable,
      show: true
    },

    {
      link: '/studymaterial',
      text: 'Study Materials',
      icon: StudyMaterial,
      show: (props.auth.inst_set_up[18]  && !props.auth.parentslogin)
    },
    
    {
      link: '/live',
      text: 'Live Classes',
      icon: LiveClass,
      show: (props.auth.inst_set_up[7] ||  props.auth.inst_set_up[16]) 
    },
    
    {
      link: '/exam',
      text: 'My Exam',
      icon: MyExam,
      show: props.auth.inst_set_up[1]
    },
    {
      link: '/report',
      text: 'Exam Reports',
      icon: ExamReport,
      show: true
    },

    {
      link: '/document',
      text: 'Documents',
      icon: Document,
      show: (props.auth.inst_set_up[18]  && !props.auth.parentslogin)
    },
    
    {
      link: '/assignment',
      text: 'Assignments',
      icon: Assignment,
      show: props.auth.inst_set_up[9]
    },
    
    {
      link: '/product',
      text: 'My Products',
      icon: Products,
      show: props.auth.inst_set_up[15]
    },
  ]

  const social_list = [
    {
      link: '',
      icon: Facebook,
    },
    {
      link: '',
      icon: Whatsapp,
    },
    {
      link: '',
      icon: Youtube,
    },
    {
      link: '',
      icon: instagram,
    },
  ]

  const togglePopup = () => {
    setIsOpen(!isOpen)
  }
  const toggleAnnouncement = () => {
    setIsAnnouncement(!isAnnouncement)
  }

  const checkURL = (url) => {
    if (url.includes('http') || url.includes('https')) {
      return url
    } else {
      return 'https://' + url
    }
  }

  useEffect(() => {
    const script = document.createElement("script");
    try {
      if (config.env == 'prod') {
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-SYMKRC0C5B";
        script.async = true;
        document.head.appendChild(script);
        eval("window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}  gtag('js', new Date()); gtag('config', 'G-SYMKRC0C5B');")
      } else {
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-7NENVW1MR5";
        script.async = true;
        document.head.appendChild(script);
        eval("window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-7NENVW1MR5');")
      }
    } catch (e) {
    }
  }, [])


  return (
    <>
      <div className='header_container'>
        <div className='header_top'>
          <div className='header_top_left'>
            {/* <div className='header_top_left_img'> */}
            {/* {props.auth.logo_url == null || props.auth.logo_url == '' ? (
                <img src={proc} />
              ) : (
                <img src={props.auth.logo_url} height='25' width='auto' />
              )} */}
            {/* </div> */}

            <div className='header_top_left_text'>
              <p style={{fontWeight:"bold"}}>{props.auth.institute_name}</p>
            </div>
          </div>
          <div className='header_top_right'>
            <div style={{ marginRight: "26px", cursor: "pointer" }}>
              {props.auth.help_url && (
                <a
                  onClick={() => {
                    window.open(checkURL(props.auth.help_url))
                  }}
                >
                  <img style={{ height: "35px", width: "35px" }} src={Help} />
                </a>
              )}
            </div>
            <div className='notification_div'>
              <a
                style={{
                  border: '0',
                  outline: '0',
                  backgroundColor: 'none',
                  cursor: 'pointer',
                }}
                href={path + `/messages`}
              >
                <img
                  style={{ backgroundColor: '#fafafa' }}
                  src={Notification}
                />
              </a>
            </div>
            <div className="Announcement_Icon">
              <button onClick={toggleAnnouncement} >
                <img style={{ width: "25px", height: "25px", outline: "0", backgroundColor: "0" }}
                  src={AnnouncementImg}
                  width='20px'
                  height='15px'

                />
              </button>
              {isAnnouncement && <Announcement toggleAnnouncement={toggleAnnouncement} />}

            </div>

            <div className='profile_div'>
              <button onClick={togglePopup}>
                {/* <img 
              src={props.auth.photo_url==="na"? imgprofile :props.auth.photo_url}
               width="25" height="25" style={{borderRadius:"50%",overflow:"hidden"}} /> */}
                <img style={{ border: "1px solid #0976B7", borderRadius: "50%", width: "25px", height: "25px", outline: "0", backgroundColor: "0" }}
                  src={
                    props.auth.photo_url ? props.auth.photo_url : imgprofile
                  }
                  width='20px'
                  height='15px'

                />
              </button>
              {isOpen && (
                <Profile_page setIsOpen={setIsOpen} handleClose={togglePopup} />
              )}
            </div>
          </div>
        </div>
        <div className='header_menubar'>
          <div className='header_menu'>
            {list.map((objLink, i) => {
              let current_url = window.location.href
              const urlMatch = new RegExp(`^${path + objLink.link}`, 'i')
              if (urlMatch.test(current_url)) {
                return (
                  objLink.show &&
                  <div className='menu_button_on_click'>
                    <a href={path + objLink.link}>
                      <div>
                        {' '}
                        <img src={objLink.icon} />
                      </div>
                      {objLink.text}{' '}
                    </a>{' '}
                  </div>
                )
              } else {
                return (
                  objLink.show &&
                  <div className='menu_button'>
                    <a href={path + objLink.link}>
                      <div>
                        {' '}
                        <img src={objLink.icon} />
                      </div>
                      {objLink.text}{' '}
                    </a>{' '}
                  </div>
                )
              }
            })}
          </div>
          <div className='header_social_icon'>
            <div>
              {props.auth.fg_page_url && (
                <a
                  onClick={() => {
                    window.open(checkURL(props.auth.fg_page_url))
                  }}
                >
                  <img style={{ height: "35px", width: "35px" }} src={Facebook} />
                </a>
              )}
            </div>
            <div>
              {props.auth.linkedin_url && (
                <a
                  onClick={() => {
                    window.open(checkURL(props.auth.linkedin_url))
                  }}
                >
                  <img style={{ height: "35px", width: "35px" }} src={Linkdin} />
                </a>
              )}
            </div>
            <div>
              {props.auth.whatsapp_url && (
                <a
                  onClick={() => {
                    window.open(checkURL(props.auth.whatsapp_url))
                  }}
                >

                  <img style={{ height: "35px", width: "35px" }} src={Whatsapp} />
                </a>
              )}
            </div>
            <div>
              {props.auth.youtube_url && (
                <a
                  onClick={() => {
                    window.open(checkURL(props.auth.youtube_url))
                  }}
                >
                  <img style={{ height: "35px", width: "35px" }} src={Youtube} />
                </a>
              )}
            </div>
            <div>
              {props.auth.instagram_url && (
                <a
                  onClick={() => {
                    window.open(checkURL(props.auth.instagram_url))
                  }}
                >
                  <img style={{ height: "35px", width: "35px" }} src={instagram} />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({ auth: state.auth })
export default connect(mapStateToProps)(Header)
