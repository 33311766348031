import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { siteRoutes, dashboardRoutes } from "./allroutes";

function ParamsExample(props) {
  const favicon = document.getElementById("favicon");
  favicon.href = localStorage.getItem('feviconIcon');
  console.log("route props", props)
  function PrivateRoute({ children, ...rest }) {
    return (

      <Route {...rest} render={({ location }) =>
        props.auth.user_id !== 0 ? (
          children
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
      />
    );

  }


  return (
    <Router>
      <Switch>
        {
          siteRoutes.map((routes, i) => {
            return (
              <Route key={i}
                path={routes.path}
                exact={routes.exact}
                strict={routes.strict}
                children={<routes.component />}
              />
            )

          })

        }

        <PrivateRoute path="/">
          <Switch>
            {
              dashboardRoutes.map((routes, i) => {
                return (
                  <Route key={i}
                    path={routes.path}
                    exact={routes.exact}
                    strict={routes.strict}
                    children={<routes.component />}
                  />
                )
              })
            }
          </Switch>
        </PrivateRoute>
      </Switch>
    </Router>
  )
}


const mapStateToProps = state => ({ auth: state.auth })
export default connect(mapStateToProps)(ParamsExample)
