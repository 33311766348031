import React from "react"
import "./announcement.css"
import { connect } from "react-redux"
import Notification from "../../../assets/header/notfication.svg"
import announce_background from "../../../assets/header/announce.svg"
import announce_bell from "../../../assets/header/announce_bell.svg"
import announce_cross from "../../../assets/header/announce_cross.svg"

const Announcement = (props) =>{return(<>
        <div className='Announcementpopup-box'>
        <div className='Announcement_box'>
            <div className="Announcement_Header">
                <div className="Announcement_title" style={{display:"flex"}} >
                    <p><img src={announce_bell} height="90%"/></p>
                   <div style={{height:"100%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                     <p style={{fontWeight:"bold"}}>&nbsp;&nbsp;&nbsp;&nbsp;Announcement :</p>
                   </div>
                </div>
                <div className="Announcement_button"><button onClick={props.toggleAnnouncement}><img style={{paddingTop:"7px",paddingRight:"7px"}} src={announce_cross} width='15px' height='15px'/></button></div>
            </div>
            {props.auth.inst_announcement &&
                <div style={{position:"relative",height:"50vh",borderBottomLeftRadius:"7px",borderBottomRightRadius:"7px",borderTop:"2px solid grey"}} >
                <div style={{position:"absolute",height:"100%",width:"35%"}}><img style={{width:"100%",borderRadius:"7px"}} src={announce_background} height="100%"/></div>
                <div style={{position:"absolute",top:"15%",right:"3%",width:"60%",height:"70%",maxHeight:"70%",overflowY:"auto",boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",borderRadius:"4px"}}>
                    <div className="Announcement_body" dangerouslySetInnerHTML={{ __html: props.auth.inst_announcement }}></div>
                </div>
            </div>
}
            {!props.auth.inst_announcement &&
                <div style={{display:"flex" , alignItems:"center" , justifyContent:"center",borderTop:"2px solid grey",borderBottomLeftRadius:"7px",borderBottomRightRadius:"7px"}}>
                <img src={Notification} height= "250px" width="250px" />
            </div> }    
    </div>
</div>
    </>)
}

const mapStateToProps = state => ({ auth: state.auth })
export default connect(mapStateToProps)(Announcement)