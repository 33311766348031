const URL = {
    testbed: {
        PROCTUR_API_URL         : 'https://test999.proctur.com/StdMgmtWebAPI',
        CUSTOM_PRODUCT_URL      : 'https://productsit.proctur.com',
        OLD_EXAMDESK_API_URL    : 'https://test999.examdesk.co/api/v1',
        EXAMDESK_URL            : 'https://test999.examdesk.co',
        EXAMDESK_API_URL        : 'https://test999.examdesk.co/api/public/v1',
        EXAMDESK_API_URL_2      : 'https://test999.examdesk.co/api/public/v1',
        WEB_TEST_PANEL_URL      : 'http://testpanel999.examdesk.co',
        REPORT_DOWNLOAD_URL     : 'https://examdesk.co/htmltopdf/?url=https://test999.examdesk.co/administrator/printTest/'
    },
    prod: {
        PROCTUR_API_URL         : 'https://api.proctur.com/StdMgmtWebAPI',
        CUSTOM_PRODUCT_URL      : 'https://productprod.proctur.com',
        OLD_EXAMDESK_API_URL    : 'https://examdesk.co/api/v1/',
        EXAMDESK_URL            : 'https://examdesk.co',
        EXAMDESK_API_URL        : 'https://loadapi.examdesk.co/v1',
        EXAMDESK_API_URL_2      : 'https://api.examdesk.co/v1',
        WEB_TEST_PANEL_URL      : 'https://onlinetest.eduims.com',
        REPORT_DOWNLOAD_URL     : 'https://examdesk.co/htmltopdf/?url=https://examdesk.co/administrator/printTest/'
    },
    uat: {
        PROCTUR_API_URL         : 'https://uat.proctur.com/StdMgmtWebAPI',
        CUSTOM_PRODUCT_URL      : 'https://productuat.proctur.com',
        OLD_EXAMDESK_API_URL    : 'https://apiv2.examdesk.co/v1',
        EXAMDESK_URL            : 'https://uat.examdesk.co',
        EXAMDESK_API_URL        : 'https://apiv2.examdesk.co/v1',
        EXAMDESK_API_URL_2      : 'https://apiv2.examdesk.co/v1',
        WEB_TEST_PANEL_URL      : 'https://testuat.eduims.com',
        REPORT_DOWNLOAD_URL     : 'https://examdesk.co/htmltopdf/?url=https://uat.examdesk.co/administrator/printTest/'
    },
    sit: {
        PROCTUR_API_URL         : 'https://apiv2.proctur.com/StdMgmtWebAPI',
        CUSTOM_PRODUCT_URL      : 'https://devsit.proctur.com',
        OLD_EXAMDESK_API_URL    : 'https://sitapiv2.examdesk.co/v1',
        EXAMDESK_URL            : 'https://sitv2.examdesk.co',
        EXAMDESK_API_URL        : 'https://sitapiv2.examdesk.co/v1',
        EXAMDESK_API_URL_2      : 'https://sitapiv2.examdesk.co/v1',
        WEB_TEST_PANEL_URL      : 'https://sittestv2.examdesk.co',
        REPORT_DOWNLOAD_URL     : 'https://examdesk.co/htmltopdf/?url=https://sitv2.examdesk.co/administrator/printTest/'
    },
}
export default URL;
